<template>
  <div class="fullScreen" :class="{ show: showEditDialog }">
    <div v-loading="loading">
      <Crumbs>
        <el-breadcrumb-item slot="after"> 查看商务信息 </el-breadcrumb-item>
      </Crumbs>
      <div class="fullScreenMain">
        <el-tabs v-model="activeName" class="fullScreenMainHeader">
          <el-tab-pane name="0" label="基本信息"></el-tab-pane>
          <el-tab-pane name="1" label="附件"></el-tab-pane>
          <el-tab-pane
            name="2"
            label="商务团队分工"
            v-if="(options.id && ActiveName == 2) || ActiveName == ''"
          ></el-tab-pane>
          <el-tab-pane
            name="3"
            label="商务追踪"
            v-if="(options.id && ActiveName == 2) || ActiveName == ''"
          ></el-tab-pane>
          <el-tab-pane
            name="4"
            label="商务绩效"
            v-if="options.id && ActiveName == ''"
          ></el-tab-pane>
          <el-tab-pane
            name="5"
            label="关联项目"
            v-if="businessProjectList && businessProjectList.length > 0"
          >
          </el-tab-pane>
          <el-tab-pane name="6" label="投标信息" v-if="bidManagementResp.id"> </el-tab-pane>
        </el-tabs>

        <div class="fullScreenMainCon">
          <Manage
            width="600px"
            ref="Manage"
            v-show="activeName == 0"
            :BusinessId="options.id"
            :options="form"
            :isShowSuggest="true"
            :isBusinessLog="true"
            @getBusinessFile="getBusinessFile"
            @showEditDialog="showEditDialog = true"
          ></Manage>

          <div v-show="opinionRecordList.length > 0 && activeName == '0'">
            <div class="text-center">
              <h3 v-if="options.id">意见区：</h3>
              <template>
                <div class="text-center-top" v-for="i in opinionRecordList" :key="i.id">
                  <span class="text-center-top-left">
                    <el-button class="text-btn" type="success" disabled v-if="i.isCheckPass === '1'"
                      >通过</el-button
                    >
                    <el-button type="info" class="text-btn" disabled v-else>不通过</el-button>
                    <span class="opinion">审批意见：{{ i.opinion }}</span>
                  </span>
                  <span class="text-center-top-right">
                    <span class="name">处理人：{{ i.auditUserName }}</span>
                    <span class="day">审批时间：{{ i.auditDate | timeFormat }}</span>
                  </span>
                </div>
              </template>
            </div>
          </div>

          <Annex :manageId="options.id" :file_Data="file_Data" v-show="activeName == 1"></Annex>
          <Assign width="600px" :ManageId="options.id" v-show="activeName == 2"></Assign>
          <Business width="600px" :BusinessId="options.id" v-show="activeName == 3"></Business>
          <DistriBution
            width="600px"
            :options="{ businessManageId: options.id }"
            :Disabled="true"
            v-if="activeName == 4"
          ></DistriBution>
          <BusinessRelevanceProject v-if="activeName == 5"></BusinessRelevanceProject>
          <BidInBusiness
            v-show="activeName == 6"
            :formData="bidManagementResp"
            ref="BidInBusinessRef"
            editType="detail"
          ></BidInBusiness>
        </div>

        <div class="fullScreenOperation">
          <!-- <template v-if="isSubmit">
            <el-button
              v-if="form.list_s != 2 && (form.status == '0' || form.status == '21')"
              type="success"
              :loading="loading"
              @click="handleSave(1)"
            >
              提交商务部
            </el-button>
            <el-button
              v-if="form.list_s != 2 && (form.status == '0' || form.status == '21')"
              type="success"
              :loading="loading"
              @click="handleSave(0)"
            >
              暂存
            </el-button>
            <el-button
              v-if="showWithdraw && form.status == '1'"
              type="success"
              :loading="loading"
              @click="handleSave(0)"
            >
              撤回
            </el-button>
          </template> -->

          <el-button
            v-if="permission(['SWXX_DELETE'])"
            type="danger"
            :loading="loading"
            @click="delFn"
          >
            删除商务信息
          </el-button>
          <el-button
            v-if="
              businessInformation &&
              businessInformation.isFinality &&
              businessInformation.isFinality &&
              businessInformation.status < 5
            "
            class="finality"
            :loading="loading"
            @click="dialogFormVisible = true"
          >
            商务终结
          </el-button>
          <el-button type="info" @click="re">返回</el-button>
        </div>
      </div>
    </div>
    <EditDialog :isShow.sync="dialogFormVisible">
      <div slot="title" class="title">商务终结</div>
      <div slot="content" class="content">
        <el-form :model="examineForm" :rules="rules_form" ref="ruleForm">
          <el-form-item prop="opinion">
            <el-input type="textarea" placeholder="请输入原因" v-model="examineForm.opinion"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="success" :loading="loading" @click="finalityFn">确定终结</el-button>
        <el-button type="info" @click="dialogFormVisible = false">取消</el-button>
        
      </div>
    </EditDialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { phone2 } from '@/util/regular'
import { getInputValue, delcommafy, numberToCurrencyNo } from '@/util/jsencrypt'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Annex: () => import('./Annex.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    Assign: () => import('@/views/publicView/Assign.vue'),
    Business: () => import('@/views/publicView/Business.vue'),
    DistriBution: () => import('@/views/publicView/DistriBution.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Manage: () => import('@/views/publicView/Manage.vue'),
    BusinessRelevanceProject: () => import('@/views/publicView/business-relevance-project.vue'),
    BidInBusiness: () => import('@/views/publicView/bid/BidInBusiness.vue'),
  },

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    showWithdraw: {
      //是否有撤回
      type: Boolean,
      default: false,
    },
    disabled: {
      //是否有撤回
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    ActiveName: {
      type: [Number, String],
      default: '',
    },
    isSubmit: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      businessProjectList: state => state.business.businessProjectList,
      bidManagementResp: state => state.business.bidManagementResp,
      businessInformation: state => state.business.businessInformation,
    }),
  },
  data() {
    return {
      showEditDialog: false,
      loading: false,
      submitLoading: false,
      form: { ourContractAmount: 0, vatRate: 0 },
      activeName: '0',
      type: 'portrait',
      file_Data: [],
      list_s: '0', //列表进入状态
      val: {},
      rules_form: {
        opinion: [{ required: true, message: '请输入商务终结原因', trigger: 'blur' }],
      },
      opinionRecordList: [],
      winResultData: {},
      examineForm: {
        statusDescription: '',
      },
      dialogFormVisible: false,
    }
  },
  inject: ['re'],
  created() {},
  watch: {
    bidManagementResp: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.winResultData = {
          fkId: newVal.id,
          isWin: newVal.bidStatus,
          fileList: newVal.bidManagementFiles,
        }
      },
    },
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.showEditDialog = newVal
        if (newVal) {
          this.opinionRecordList = []
        }
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.val = newVal
        this.list_s = this.val.list_s
        if (newVal.id) {
          this.form.list_s = newVal.list_s
        } else {
          this.form = {
            ...newVal,
            initiator: this.userInfo.userName,
            initiatorId: this.userInfo.staffId,
          }
          this.file_Data = []
        }
      },
    },
  },
  methods: {
    finalityFn() {
      this.$confirm('确定终结该商务？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.loading = true

          this.$api.businessManage
            .finality({
              id: this.options.id,
              statusDescription: this.examineForm.opinion,
            })
            .then(res => {
              this.loading = false
              this.showEditDialog = false
              this.$message.success('终结成功！')
              this.re()
            })
            .catch(err => {
              this.loading = false
              console.log(err)
            })
        })
        .catch(() => {})
    },
    getBusinessFile(arr) {
      this.file_Data = arr
    },
    delFn() {
      this.$confirm('此操作将删除该商务信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.businessManage
            .deleteBusiness(this.options.id)
            .then(res => {
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
              this.showEditDialog = false
              this.re()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
  },
}
</script>

<style scoped lang="scss">
.text-center {
  width: 100%;
  height: 300px;
  //   border: 1px solid #000;
  overflow: auto;
  padding: 16px 0;
  .text-center-top {
    margin-bottom: 10px;
    color: #000;
    background-color: #f3f3f5;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text-center-top-left {
      // width: 50%;
      flex: 1;
      display: flex;
      align-items: center;
      .text-btn {
        height: 48px;
        width: 90px;
        margin-right: 20px;
      }
      .opinion {
        display: inline-block;
        margin-left: 8px;
        width: 100%;
        word-break: break-word;
        padding: 5px 0;
        font-family: PingFang SC;
        font-weight: 600;
        // font-size: 18px;
      }
    }
    .text-center-top-right {
      width: 40%;
      display: flex;
      justify-content: flex-end;
      .day {
        margin-left: 10%;
        padding-right: 8px;
      }
    }
  }
}

.danwei {
  position: absolute;
  right: 2%;
}

.form_box {
  padding-top: 20px;
}

.big_box_div {
  width: 48%;
  box-sizing: border-box;
  padding-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
}
.input_style {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #c0c4cc;
  cursor: not-allowed;
  font-size: 14px;
  border: 1px solid #e4e7ed;
  height: 40px;
  border-radius: 5px;
  line-height: 40px;
  padding: 0 10px;
}
/deep/.el-loading-spinner {
  margin-top: 21%;
}
/deep/.finality {
  background-color: #e9dcdc;
}
</style>
